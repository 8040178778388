import React from 'react';

import Layout from '@layouts';
import { Banners, SEO, SingleArticleDev } from '@components';

const { SingleArticleBannerDev } = Banners;

const Index = () => {
  const articleData = {
    title:
      'Meet Temilouwa O. Prioleau, the first Black woman tenure-track faculty in CS in the Ivy League',
    author: 'Chambi Chachage',
    date: '10-23-2020',
    category: 'Inform',
    coverImage: 'http://placeimg.com/640/480/tech',
    coverImageCredits: 'alfru94',
    content: `<p>
    When I was growing up as a science student in Tanzania, I wondered if there was any theory named after an African. I recall how fond we were of nicknaming ourselves after some famous European scientists. Until today we still call one of our classmates Schrödinger, the name of the Nobel Prize winner in Physics. It was so fascinating to learn about the likes of Amadeo Avogadro, Blaise Pascal, Daniel Bernoulli, and many other white men whose findings are named after them.
  </p>
  <p>
    Error, accusantium quos soluta voluptatem aliquam maiores eaque. Itaque, repellat.
    Odio assumenda architecto accusamus modi quas? Lorem ipsum dolor sit amet consectetur,
    adipisicing elit. Error, accusantium quos soluta voluptatem aliquam maiores eaque.
    Itaque, repellat. Odio assumenda architecto accusamus modi quas?
  </p>
  <p>
    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error, accusantium quos
    soluta voluptatem aliquam maiores eaque. Itaque, repellat. Odio assumenda architecto
    accusamus modi quas?
  </p>
  <p>
    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error, accusantium quos
    soluta voluptatem aliquam maiores eaque. Itaque, repellat. Odio assumenda architecto
    accusamus modi quas?
  </p>`,
    aboutAuthor:
      '<strong>Chambi Chachage</strong> is Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel.',
  };
  const finalTitle = articleData.title.toUpperCase();
  return (
    <Layout
      bannerHeight='700px'
      bannerStyle={{ backgroundImage: 'none' }}
      dynamicBanner={articleData.coverImage}
      bannerChildren={<SingleArticleBannerDev articleData={articleData} />}
    >
      <SEO title={finalTitle} />
      <SingleArticleDev articleData={articleData} />
    </Layout>
  );
};

export default Index;
